<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			:inline="true"
		>
			<el-form-item label="操作对象编号" prop="name" label-width="120px">
				<el-input
					v-model="queryParams.childUserName"
					clearable
					placeholder="请输入直属代理商编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<!-- <el-form-item label="操作人编号" prop="name" label-width="120px">
				<el-input
					v-model="queryParams.childUserNo"
					clearable
					placeholder="请输入商户编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item> -->
			<el-form-item label="操作时间" label-width="120px">
				<el-date-picker
					v-model="createDateRange"
					size="small"
					value-format="yyyy-MM-dd"
					type="daterange"
					range-separator="-"
					style="width: 240px"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
				/>
			</el-form-item>
			<el-form-item label="操作类型" prop="name" label-width="120px">
				<el-select
					v-model="queryParams.hardwareNo"
					size="small"
					placeholder="请选择机具型号"
					style="width: 240px"
				>
					<el-option label="全部" value=""></el-option>
					<el-option
						v-for="(value, key) in dicts.TERMINAL_OPER_TYPE"
						:key="value"
						:label="value"
						:value="key"
					></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<!-- 按钮 -->
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'TERMINAL_INFO_QUERY'"
				>
					查询
				</el-button>
				<!-- <el-button type="warning" icon="el-icon-download" size="mini" @click="handleExport" v-permission="'TERMINAL_INFO_EXPORT'" > 导出 </el-button> -->
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { TerminalApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		},
		pageNo: {
			type: Number,
			default: 1
		},
		pageSize: {
			type: Number,
			default: 10
		}
	},
	data() {
		return {
			queryParams: {}, // 表单数据
			createDateRange: [], //创建时间
			updateDateRange: [], //完成时间
			// showCustomForm: false,
			hardwareList: [] //操作类型
		};
	},
	created() {
		// 操作类型
		TerminalApi.hardware.getHardwareList().then(res => {
			if (res.success) {
				this.hardwareList = res.data;
			}
		});
		// 打印dicts
		// console.log("this.dicts----->", this.dicts);
	},
	methods: {
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery() {
			this.queryParams = {};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		// handleExport() {
		// 	this.$confirm("确定导出", "警告", {
		// 		confirmButtonText: "确定",
		// 		cancelButtonText: "取消",
		// 		type: "warning"
		// 	}).then(async () => {
		// 		this.dealWithQueryParams();
		// 		let result = await TerminalApi.terminalInfo.export(
		// 			this.queryParams
		// 		);
		// 		if (result.success) {
		// 			this.downloadFile(result.data);
		// 		}
		// 	});
		// },
		dealWithQueryParams() {
			// if (!this.createDateRange) {
			// 	this.createDateRange = [];
			// }
			let [beginTime, endTime] = this.createDateRange;
			this.queryParams.beginTime = beginTime && beginTime + " 00:00:00";
			this.queryParams.endTime = endTime && endTime + " 23:59:59";

			let [startUpdateTime, endUpdateTime] = this.updateDateRange;
			this.queryParams.startUpdateTime =
				startUpdateTime && startUpdateTime + " 00:00:00";
			this.queryParams.endUpdateTime =
				endUpdateTime && endUpdateTime + " 23:59:59";
		},
		commitChange() {
			// this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		},
		handleRemove() {
			this.$router.push({
				name: "terminalDetail"
			});
		}
		// async handleChange() {
		// 	this.showCustomForm = true;
		// },
		// handlerCustomFormOnclose() {
		// 	this.showCustomForm = false;
		// 	this.commitChange();
		// }
	}
};
</script>

<style scoped></style>
